<button class="more-button" type="button" [ngClass]="{ light: (1 | colourTheme) === false }">
    <span class="text">
        {{ 'cabinet.home.mainCards.more' | translate }}
    </span>
    <span class="arrow">
        <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.91992 2.74666L9.17326 8L3.91992 13.2533L5.33326 14.6667L11.9999 8L5.33326 1.33333L3.91992 2.74666Z"
                fill="currentColor"
            />
        </svg>
    </span>
</button>
