import { Pipe, PipeTransform } from '@angular/core';
import Insurance from 'src/app/core/models/Insurance';
import Transaction from '../../core/models/Transactions/Transaction';

@Pipe({
    name: 'MutationsFilter',
    pure: false,
})
export class searchMutationspipe implements PipeTransform {
    value: string;
    year: string = '';
    month: string;
    date: string;
    transform(mutationsList: Array<Array<Transaction>>, filter: string): any {
        if (mutationsList) {
            return mutationsList.filter(
                (d) =>
                    d[0].timestamp.substring(0, 4).indexOf(filter) > -1 ||
                    d[0].originator.personId.indexOf(filter) > -1 ||
                    d[0].transactionId.indexOf(filter) > -1
            );
        }
    }
}
