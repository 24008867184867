<button
    class="common-button"
    [disabled]="disabled"
    [ngClass]="{
        transparent: transparent,
        'no-border': noBorder,
        light: (1 | colourTheme) === false
    }"
    [ngStyle]="{
        width: buttonSize.width,
        height: buttonSize.height,
        'font-size': fontSize + 'px',
        'font-weight': fontWeight
    }"
>
    <span class="trailing-icon">
        <ng-content select="[trailingIcon]"></ng-content>
    </span>
    {{ text }}
    <span class="leading-icon">
        <ng-content select="[leadingIcon]"></ng-content>
    </span>
</button>
