import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import DocumentEntry from '../../../core/models/dms/DocumentEntry';

@Component({
    selector: 'app-common-document',
    templateUrl: './common-document.component.html',
    styleUrls: ['./common-document.component.scss'],
})
export class CommonDocumentComponent implements OnInit {
    @Input() document: DocumentEntry;
    @Input() documentType: DocType;
    public thumbnailSrc: string;

    @Output() downloadDocument: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    downloadButtonClick(): void {
        this.downloadDocument.emit();
    }

    ngOnInit(): void {
        this.thumbnailSrc = 'data:' + this.document.thumbnail.mimeType + ';base64,' + this.document.thumbnail.picture;
    }
}

type DocType = 'small' | 'big';
