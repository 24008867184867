import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CustomConfigurationService } from '../services/custom-configuration.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NewsGuard implements CanActivate {
    constructor(private customConfigurationService: CustomConfigurationService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.customConfigurationService.news$.pipe(
            map((hasNews) => {
                if (hasNews) return true;

                this.router.navigate(['/home']);
                return false;
            }),
            catchError((error) => {
                this.router.navigate(['/home']);
                return of(false);
            })
        );
    }
}
