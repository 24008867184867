<div class="form-input" [ngClass]="{ long: isLong }">
    <mat-form-field appearance="outline">
        <mat-label>{{ title }}</mat-label>
        <input
            matInput
            type="text"
            [(ngModel)]="value"
            [formControl]="formControl"
            [name]="nameField()"
            autocomplete="off"
        />
    </mat-form-field>
</div>
