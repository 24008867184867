import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setHttpParams } from '@shared/functions/http-params-util';
import { BehaviorSubject, Observable } from 'rxjs';
import { Content, ContentType } from 'src/app/core/models/Content';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CMService {
    get apiUrl(): string {
        return environment.clanDataApiUrl + '/content-management';
    }

    constructor(public http: HttpClient) {}

    createContent(content): Observable<any> {
        const url = `${this.apiUrl}`;
        return this.http.post<any>(url, content);
    }

    getContent(filters): Observable<any> {
        const url = `${this.apiUrl}`;
        let params = new HttpParams();
        params = setHttpParams(params, filters);

        return this.http.get<any>(url, { params });
    }

    getContentById(id): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<any>(url);
    }

    editContent(id, data): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.put<any>(url, data);
    }

    deleteContent(id): Observable<any> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete<any>(url);
    }

    getContentByType(type: ContentType, filters: any): Observable<Content[]> {
        let params = new HttpParams();
        params = setHttpParams(params, filters);

        const url = `${this.apiUrl}/type/${type}`;

        return this.http.get<Content[]>(url, { params });
    }
}
