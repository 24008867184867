import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-header-search-field',
    templateUrl: './header-search-field.component.html',
    styleUrls: ['./header-search-field.component.scss'],
})
export class HeaderSearchFieldComponent implements OnInit {
    @ViewChild('searchFieldInput') inputElement: ElementRef;

    constructor() {}

    focusInput(): void {
        setTimeout(() => {
            this.inputElement.nativeElement.focus();
        }, 0);
    }

    ngOnInit(): void {}
}
