<dialog #imageCropperDialog class="custom-dialog">
    <div>{{ 'cabinet.news.wizard.prepareNewsStep.cropImage' | translate }}</div>
    <div>
        <image-cropper
            [onlyScaleDown]="true"
            [maintainAspectRatio]="data.maintainAspectRatio"
            [aspectRatio]="data.aspectRatioX / data.aspectRatioY"
            [imageFile]="data.image"
            [resizeToHeight]="data.resizeToHeight"
            [resizeToWidth]="data.resizeToWidth"
            (imageCropped)="imageCropped($event)"
            (cropperReady)="cropperReady()"
        ></image-cropper>
    </div>

    <div class="dialog-actions">
        <button class="button" (click)="sendCroppedImage()">
            {{ 'cabinet.news.wizard.prepareNewsStep.finishCropping' | translate }}
        </button>
    </div>
    <div *ngIf="isLoading" class="loading-overlay">
        <mat-spinner></mat-spinner>
    </div>
</dialog>
