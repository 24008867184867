import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import Paragraph from '@editorjs/paragraph';
import { LayoutBlockTool } from 'editorjs-layout';
import Checklist from '@editorjs/checklist';
import Header from '@editorjs/header';
import Code from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import Image from '@editorjs/image';
import InlineCode from '@editorjs/inline-code';
import Link from '@editorjs/link';
import List from '@editorjs/list';
import Marker from '@editorjs/marker';
import Table from '@editorjs/table';
import Warning from '@editorjs/warning';
import AttachesTool from '@editorjs/attaches';
import Quote from '@editorjs/quote';

@Component({
    selector: 'app-content-view',
    templateUrl: './content-view.component.html',
    styleUrls: ['./content-view.component.scss'],
})
export class ContentViewComponent implements AfterViewInit, OnChanges {
    constructor() {}

    @ViewChild('editor', { read: ElementRef })
    editorElement: ElementRef;

    private editor: EditorJS;

    @Input('content')
    content: any;

    data;

    ngAfterViewInit() {
        this.initializeEditor();
    }

    ngOnChanges() {
        if (!this.editor) return;

        this.loadEditorContent();
    }

    loadEditorContent() {
        if (this.editor && this.content) {
            this.renderContent();
        }
    }

    initializeEditor() {
        let editorJSConfig = {
            readOnly: true,
            inlineToolbar: false,
            minHeight: 200,
            tools: {
                image: {
                    class: Image,
                },
                attaches: {
                    class: AttachesTool,
                },
                list: {
                    class: List,
                },
                checklist: {
                    class: Checklist,
                },
                quote: {
                    class: Quote,
                },
                warning: Warning,
                marker: {
                    class: Marker,
                },
                code: {
                    class: Code,
                },
                paragraph: {
                    class: Paragraph,
                    config: {
                        preserveBlank: true,
                    },
                },
                delimiter: Delimiter,
                inlineCode: {
                    class: InlineCode,
                },
                linkTool: Link,
                table: {
                    class: Table,
                },
            },
            holder: this.editorElement.nativeElement,
        };

        this.editor = new EditorJS({
            readOnly: true,
            inlineToolbar: false,
            minHeight: 200,
            tools: {
                twoColumns: {
                    class: LayoutBlockTool,
                    config: {
                        EditorJS,
                        editorJSConfig,
                        enableLayoutEditing: false,
                        enableLayoutSaving: false,
                        initialData: {
                            itemContent: {
                                1: {
                                    blocks: [],
                                },
                                2: {
                                    blocks: [],
                                },
                            },
                            layout: {
                                type: 'container',
                                id: '',
                                className: '',
                                style: 'display: flex; justify-content: space-around; padding: 5px; ',
                                children: [
                                    {
                                        type: 'item',
                                        id: '',
                                        className: '',
                                        style: 'padding: 5px; width: 50%',
                                        itemContentId: '1',
                                    },
                                    {
                                        type: 'item',
                                        id: '',
                                        className: '',
                                        style: 'padding: 5px; width: 50%',
                                        itemContentId: '2',
                                    },
                                ],
                            },
                        },
                    },
                },
                header: {
                    class: Header,
                },
                attaches: {
                    class: AttachesTool,
                },
                image: {
                    class: Image,
                },
                list: {
                    class: List,
                },
                checklist: {
                    class: Checklist,
                },
                quote: {
                    class: Quote,
                },
                warning: Warning,
                marker: {
                    class: Marker,
                },
                code: {
                    class: Code,
                },
                paragraph: {
                    class: Paragraph,
                    config: {
                        preserveBlank: true,
                    },
                },
                delimiter: Delimiter,
                inlineCode: {
                    class: InlineCode,
                },
                linkTool: Link,
                table: {
                    class: Table,
                },
            },
            holder: this.editorElement.nativeElement,
            data: this.content || null,
        });

        this.editor.isReady.then(() => {
            if (this.content) {
                this.renderContent();
            }
        });
    }

    renderContent() {
        this.editor.render(this.content).then(() => {
            const attachParents = document.querySelectorAll('.cdx-attaches--with-file');
            if (attachParents.length > 0) {
                attachParents.forEach((attachParent) => {
                    const title = attachParent.querySelector('.cdx-attaches__title') as HTMLElement;
                    title.style.width = 'fit-content';
                    title.style.cursor = 'pointer';
                    const anchor = attachParent.querySelector('a');
                    anchor.style.display = 'none';
                    const fileUrl = anchor?.href;
                    title.addEventListener('click', (event) => {
                        event.preventDefault();

                        if (fileUrl) {
                            const downloadLink = document.createElement('a');
                            downloadLink.href = fileUrl;
                            downloadLink.download = title.innerHTML;
                            downloadLink.style.display = 'none';

                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                        }
                    });
                });
            }
        });
    }
}
