<div class="vote-meter" [ngClass]="{ light: (1 | colourTheme) === false, active: isActive }">
    <div class="vote-meter__main" [ngClass]="{ light: (1 | colourTheme) === false }">
        <div class="vote-meter__colors">
            <img
                *ngIf="statusColor == 'yes' && (1 | colourTheme) === false"
                src="../../../../assets/img/vote/green.svg"
            />
            <img *ngIf="statusColor == 'no' && (1 | colourTheme) === false" src="../../../../assets/img/vote/red.svg" />
            <img
                *ngIf="statusColor == 'neutral' && (1 | colourTheme) === false"
                src="../../../../assets/img/vote/yellow.svg"
            />
            <img
                *ngIf="statusColor == 'yes' && (1 | colourTheme) === true"
                src="../../../../assets/img/vote/green-dark.svg"
            />
            <img
                *ngIf="statusColor == 'no' && (1 | colourTheme) === true"
                src="../../../../assets/img/vote/red-dark.svg"
            />
            <img
                *ngIf="statusColor == 'neutral' && (1 | colourTheme) === true"
                src="../../../../assets/img/vote/yellow-dark.svg"
            />
        </div>
        <div class="vote-meter__numbers">
            <img *ngIf="(1 | colourTheme) === false" src="../../../../assets/img/vote/numbers.svg" />
            <img *ngIf="(1 | colourTheme) === true" src="../../../../assets/img/vote/numbers-dark.svg" />
        </div>
        <div class="vote-meter__arrow">
            <img
                *ngIf="(1 | colourTheme) === false"
                [ngStyle]="{
                    transform: 'rotate(' + degree + 'deg)'
                }"
                src="../../../../assets/img/vote/arrow-white.svg"
            />
            <img
                *ngIf="(1 | colourTheme) === true"
                [ngStyle]="{
                    transform: 'rotate(' + degree + 'deg)'
                }"
                src="../../../../assets/img/vote/arrow-dark.svg"
            />
        </div>
        <div
            class="vote-meter__btn"
            [ngClass]="{
                yes: statusColor === 'yes',
                no: statusColor === 'no',
                neutral: statusColor === 'neutral',
                active: isActive,
                light: (1 | colourTheme) === false
            }"
        >
            {{ statusColor }}
        </div>
        <div class="vote-meter__percents" [ngClass]="{ light: (1 | colourTheme) === false, active: isActive }">
            {{ percent }}<span class="percent">%</span>
        </div>
    </div>
</div>
