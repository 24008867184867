import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material/material.module';
import { AppComponent } from './modules/app/components/app/app.component';
import { CoreModule } from './core/core.module';
import { MissingLocaleService } from './missing-locale.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageData, LocaleModule } from 'models';
import { LibraryAuthModule } from 'clan-auth';
import { environment } from '../environments/environment';
import { MatSelectModule } from '@angular/material/select';
import { EnvironmentBannerComponent } from './modules/app/components/environment-banner/environment-banner.component';
import { HeaderComponent } from './modules/app/components/header/header.component';
import { HeaderSearchFieldComponent } from './modules/app/components/header-search-field/header-search-field.component';
import { UserHeaderComponent } from './modules/app/components/user-header/user-header.component';
import { UserMenuComponent } from './modules/app/components/user-menu/user-menu.component';
import { CommonModule } from '@angular/common';
import { CMModule } from './modules/content-management/cm.module';
import { SharedModule } from './shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { NavigationMenuComponent } from './modules/app/components/navigation-menu/navigation-menu.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { default as _rollupMoment } from 'moment';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { IConfig, NgxMaskModule } from 'ngx-mask';

export function HttpLoadFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

const maskConfigFunction: () => Partial<IConfig> = () => {
    return {
        thousandSeparator: "'",
    };
};

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        NavigationMenuComponent,
        HeaderSearchFieldComponent,
        UserHeaderComponent,
        UserMenuComponent,
        EnvironmentBannerComponent,
    ],
    imports: [
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSelectModule,
        NgxMaskModule.forRoot(maskConfigFunction),
        LocaleModule.forRoot(<LanguageData>environment.localeData),
        LibraryAuthModule.forRoot(environment.authApiUrl),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoadFactory,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MissingLocaleService,
            },
            useDefaultLang: false,
        }),
        CoreModule,
        CommonModule,
        SharedModule,
        MatIconModule,
        MatDatepickerModule,
        MatMomentDateModule,
        CMModule,
        MaterialModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
