import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'clan-auth';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/modules/ak/services/snackbar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private _snackbarService: SnackbarService,
        private _translateService: TranslateService,
        private _authService: AuthenticationService,
        private _router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                const errorMessage = this.translateErrorCode(error.status);

                if (error instanceof HttpErrorResponse && request.url.includes('/refresh') && error.status === 401) {
                    this._snackbarService.handleOpenSnackbar(
                        this._translateService.instant('auth.errors.sessionExpired', { message: errorMessage }),
                        this._translateService.instant('cabinet.navigation.close'),
                        'error'
                    );
                    const redirectUrl = sessionStorage.getItem('default_tenant_login_url');
                    this._authService.logout().subscribe(() => {
                        this._router.navigateByUrl(`auth${redirectUrl}`);
                    });
                }

                if (error instanceof HttpErrorResponse && request.url.includes('/auth') && error.status === 401) {
                    this._snackbarService.handleOpenSnackbar(
                        this._translateService.instant('auth.errors.invalidLogin', { message: errorMessage }),
                        this._translateService.instant('cabinet.navigation.close'),
                        'error'
                    );
                }
                if (error.error?.errorCode >= 0 || error.status === 500) {
                    const errorMessage = this.translateErrorCode(error.error.errorCode);

                    this._snackbarService.handleOpenSnackbar(
                        this._translateService.instant('cabinet.notifications.errorOccured', {
                            message: errorMessage,
                        }),
                        'Close',
                        'error'
                    );
                }
                return throwError(() => error);
            })
        );
    }

    translateErrorCode(code) {
        const key = `auth.errorCodes.${code}`;
        const translation = this._translateService.instant(key);

        if (translation.includes(key)) {
            return this._translateService.instant(`auth.errorCodes.0`);
        }
        return translation;
    }
}
