<div class="user-header">
    <div class="user-header__user header-user header-block">
        <div class="header-user__info user-info">
            <div class="profilePicture">
                <svg
                    _ngcontent-cer-c160=""
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 980.85 980.85"
                >
                    <defs _ngcontent-cer-c160="">
                        <style _ngcontent-cer-c160="">
                            .cls-1 {
                                fill: var(--primary);
                                fill-rule: evenodd;
                            }
                        </style>
                    </defs>
                    <title _ngcontent-cer-c160="">Profile Icon Blue</title>
                    <path
                        _ngcontent-cer-c160=""
                        d="M500,621.78A178.34,178.34,0,1,1,678.34,443.44,178.33,178.33,0,0,1,500,621.78Zm0,368.65c270.86,0,490.43-219.57,490.43-490.43S770.86,9.57,500,9.57,9.57,229.14,9.57,500,229.14,990.43,500,990.43ZM215.7,841.82c45.67-112.14,155.75-191.2,284.3-191.2s238.64,79,284.3,191.2c98-81.56,160.3-204.4,160.3-341.82C944.6,254.45,745.55,55.4,500,55.4S55.4,254.45,55.4,500C55.4,637.42,117.76,760.27,215.7,841.82Z"
                        transform="translate(-9.57 -9.57)"
                        class="cls-1"
                    ></path>
                </svg>
            </div>
            <span class="user-info__title header-block__title" [ngClass]="{ light: (1 | colourTheme) === false }"
                >{{ userName }}
            </span>
            <span class="userInitials" [ngClass]="{ light: (1 | colourTheme) === false }">
                {{ initials }}
            </span>
        </div>
        <div class="header-user__menu">
            <app-user-menu></app-user-menu>
        </div>
    </div>
    <button mat-icon-button (click)="doLogout()">
        <mat-icon>logout</mat-icon>
    </button>
</div>
