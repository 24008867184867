import { Options } from '@angular-slider/ngx-slider';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, concat, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, take, toArray } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MyPensionService } from './my-pension.service';

const { clanDataApiUrl } = environment;

interface InitSliderData {
    sliderOptions: Options;
    currentValue?: number;
}

@Injectable()
export class CashWithdrawalSimulationService {
    private inputData;
    isAllFilesDownloaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private minPossibleAge = 57;
    private maxPossibleAge = 70;
    private minCurrentValue = 0;
    private maxCurrentValue = 0;
    private localAgeList: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

    private calculatedValue: ReplaySubject<number> = new ReplaySubject<number>(1);
    private maxCalculatedValue: ReplaySubject<number> = new ReplaySubject<number>(1);
    private selectedAgeValue: ReplaySubject<number> = new ReplaySubject<number>(1);
    private contributionsValue: ReplaySubject<number> = new ReplaySubject<number>(1);
    private retirementCapitalValue: ReplaySubject<number> = new ReplaySubject<number>(1);
    private calculatedPercent: ReplaySubject<number> = new ReplaySubject<number>(1);

    private scriptElementArray: HTMLScriptElement[] = [];

    constructor(private http: HttpClient, private window: Window, private myPensionService: MyPensionService) {
        this.calculatedValue.next(0);
        this.maxCalculatedValue.next(0);
        this.selectedAgeValue.next(this.minPossibleAge);
        this.contributionsValue.next(0);
        this.retirementCapitalValue.next(0);
        this.calculatedPercent.next(0);
        // TODO: update after daily export files will be updated
        // this.getFilesList(3, 2, 1);
    }

    get $calculatedValue(): Observable<number> {
        return this.calculatedValue.asObservable();
    }

    get $maxCalculatedValue(): Observable<number> {
        return this.maxCalculatedValue.asObservable();
    }

    get $selectedAgeValue(): Observable<number> {
        return this.selectedAgeValue.asObservable();
    }

    get $contributionsValue(): Observable<number> {
        return this.contributionsValue.asObservable();
    }

    get $retirementCapitalValue(): Observable<number> {
        return this.retirementCapitalValue.asObservable();
    }

    get $calculatedPercent(): Observable<number> {
        return this.calculatedPercent.asObservable();
    }

    async initCalculatorBaseData(
        contributionsSliderOptions: Options,
        retirementCapitalSliderOptions: Options,
        ageSliderOptions: Options,
        isValueWasCalculated = false
    ): Promise<{
        [key: string]: InitSliderData;
    }> {
        this.inputData = { ...(this.window as any).insurantSpecificData };
        this.inputData.PENSDAT = this.inputData.PENSDAT64;
        const currentProjAGT = this.inputData.AOPROJAGHT;
        this.inputData.EKZEEM = this.inputData.EKMAXREST;

        const maxProjAGT = (this.window as any).calculateSimulation('Einkauf', this.inputData).AOPROJAGHT2;
        this.inputData.EKZEEM = 0;
        retirementCapitalSliderOptions.ceil = maxProjAGT;

        contributionsSliderOptions.ceil = this.inputData.EKMAXREST;

        const ageList = await this.myPensionService.$ageList
            .pipe(
                filter((res) => !!res.length),
                take(1)
            )
            .toPromise();
        this.localAgeList.next(ageList);

        const ageCalcData = this.setAgeSliderOptions(ageSliderOptions, ageList);

        if (!isValueWasCalculated) {
            this.selectedAgeValue.next(ageCalcData.currentValue);
            this.contributionsValue.next(0);
            this.retirementCapitalValue.next(currentProjAGT);
        }
        return {
            contributionsData: {
                sliderOptions: contributionsSliderOptions,
            },
            retirementCapitalData: {
                sliderOptions: retirementCapitalSliderOptions,
            },
            ageData: ageCalcData,
        };
    }

    private setAgeSliderOptions(ageSliderOption: Options, arrayOfAges: number[]): InitSliderData {
        this.maxCurrentValue = Math.max(...arrayOfAges);
        this.minCurrentValue = Math.min(...arrayOfAges);
        ageSliderOption.ceil =
            this.maxCurrentValue <= this.maxPossibleAge && this.maxCurrentValue >= this.minPossibleAge
                ? this.maxCurrentValue
                : this.maxPossibleAge;
        ageSliderOption.floor =
            this.minCurrentValue >= this.minPossibleAge && this.minCurrentValue <= this.maxPossibleAge
                ? this.minCurrentValue
                : this.minPossibleAge;
        return {
            sliderOptions: ageSliderOption,
            currentValue: ageSliderOption.floor,
        };
    }

    async calcSimulation(
        contributions: number,
        age: number,
        maxPossibleContributions?: number,
        maxPossibleAge?: number
    ): Promise<void> {
        const insurances = await this.myPensionService.$insurances
            .pipe(
                filter((res) => !!res.length),
                map((res) => res[0]),
                take(1)
            )
            .toPromise();
        const conversionRate =
            insurances.benefits.retirement.projections[
                age > Object.values(insurances.benefits.retirement.projections).length - 1
                    ? age - this.minPossibleAge + 1
                    : age
            ].conversionRate;
        if (!this.inputData) {
            this.inputData = { ...(this.window as any).insurantSpecificData };
            this.inputData.PENSDAT = this.inputData.PENSDAT64;
            const currentProjAGT = this.inputData.AOPROJAGHT;
            this.inputData.EKZEEM = 0;
        }
        this.inputData.BERDAT = moment().format('yyyy-MM-DD');
        this.inputData.EKZEEM = contributions;
        const results = { ...(this.window as any).calculateSimulation('Einkauf', this.inputData) };
        const calcResult = Math.round(results.multiAOPROJAGHT2[age - this.minPossibleAge] / conversionRate);
        this.calculatedValue.next(calcResult || 0);
        this.retirementCapitalValue.next(results.AOPROJAGHT2);

        const inputDataClone = { ...this.inputData };
        inputDataClone.EKZEEM = maxPossibleContributions;
        maxPossibleAge = maxPossibleAge - 57;
        const conversationRateMaxPossible = insurances.benefits.retirement.projections[maxPossibleAge]?.conversionRate;
        const maxCalcResult = Math.round(
            (this.window as any).calculateSimulation('Einkauf', inputDataClone).multiAOPROJAGHT2[
                maxPossibleAge - this.minPossibleAge
            ] / conversationRateMaxPossible
        );
        this.calculatedPercent.next((calcResult * 100) / maxCalcResult);
    }

    setSelectedValue(value: number, sliderName: 'contributions' | 'capital' | 'age'): void {
        sliderName === 'capital'
            ? this.retirementCapitalValue.next(value)
            : sliderName === 'contributions'
            ? this.contributionsValue.next(value)
            : this.selectedAgeValue.next(value);
    }

    clearScriptArray(): void {
        this.scriptElementArray.forEach((element) => {
            let oldElement = element.parentNode.removeChild(element);
            oldElement = null;
            element = null;
        });

        this.scriptElementArray = [];
    }
}
