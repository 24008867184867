import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable()
export class MissingLocaleService implements MissingTranslationHandler {
    constructor() {}

    handle(params: MissingTranslationHandlerParams) {
        return `${params.key} is missing in ${params.translateService.currentLang} locale`;
    }
}
