import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'translateEnum', pure: false })
export class TranslateEnumPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: string, translationPrefix: string, camelCase: boolean = true) {
        if (!value) {
            return '';
        }

        if (value.length === 0) {
            return value;
        }

        let propertyValue = value;

        if (camelCase) {
            propertyValue = value[0].toLowerCase();

            if (value.length > 1) {
                propertyValue = propertyValue + value.slice(1);
            }
        } else {
            propertyValue = propertyValue.toLowerCase();
        }

        propertyValue = propertyValue.replace(/\s+/g, '');
        return this.translateService.instant(`${translationPrefix}.${propertyValue}`);
    }
}
