<div class="amount-line">
    <div
        class="amount-line-bubble"
        *ngIf="currentAmount > 9"
        [ngClass]="{ 'blur-currency': (currentAmount | currencyVisibility) === 'hide' && !isDate }"
        [ngStyle]="{ left: bubblePosition }"
    >
        {{ isDate ? dateBubble : (currentAmount | splitNumberWithComma) }}
    </div>
    <div class="amount-line__line" [ngClass]="{ light: (1 | colourTheme) === false }" (click)="clickLine($event)">
        <div class="amount-line__line--to-current" [ngStyle]="{ width: percentsForCurrent + '%' }"></div>
        <div class="amount-line__line--current" [ngStyle]="{ left: 'calc(' + percentsForCurrent + '% - 10px)' }"></div>
        <div class="amount-line__line--list" id="amount-line-object">
            <div class="amount-line__line--list_point" *ngFor="let item of points; index as i">
                <div class="amount-line__line--list_point-dot" [ngStyle]="{ left: item.left + '%' }"></div>
                <div
                    class="amount-line__line--list_point-text"
                    [ngClass]="{
                        'blur-currency': (item.count | currencyVisibility) === 'hide' && !isDate,
                        light: (1 | colourTheme) === false
                    }"
                    [ngStyle]="{ left: item.leftBody }"
                >
                    {{ item.count | splitNumberWithComma }}
                </div>
            </div>
        </div>
    </div>
</div>
