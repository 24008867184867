<app-environment-banner></app-environment-banner>
<div class="navigation-menu">
    <app-navigation-menu
        style="background-color: white"
        *ngIf="isAuthenticated"
        [@openClose]="isOpen ? 'open' : 'closed'"
        (navigationToggle)="toggleNavigation($event)"
    ></app-navigation-menu>

    <div class="navigation-menu__content-wrapper">
        <app-user-header *ngIf="isAuthenticated"></app-user-header>
        <router-outlet></router-outlet>
    </div>
</div>
