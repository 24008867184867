import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
    formControl: FormControl = new FormControl(new Date());
    @Output() valueChanged: EventEmitter<Date> = new EventEmitter();
    @Input() type: 'input' | 'icon' = 'icon';

    constructor() {}

    ngOnInit(): void {
        this.formControl.valueChanges.subscribe((value) => {
            return this.valueChanged.emit(value);
        });
    }
}
