export default class TransactionResponce {
    originator: Originator;
    transactionType: TransactionType;
    data: object;
    assignee: string;
}

export class Originator {
    companyId: string;
    pensionFundId: string;
    foundationId: string;
    personId?: string;
}

export enum TransactionType {
    EMPLOYER_ADMISSION = 'EMPLOYER_ADMISSION',
    EMPLOYER_TERMINATION = 'EMPLOYER_TERMINATION',
    EMPLOYER_MUTATION_SALARY_NEW = 'EMPLOYER_MUTATION_SALARY_NEW',
    EMPLOYER_MUTATION_SALARY_DELETION = 'EMPLOYER_MUTATION_SALARY_DELETION',
    EMPLOYEE_MUTATION_ADDRESS = 'EMPLOYEE_MUTATION_ADDRESS',
    EMPLOYEE_PURCHASE = 'EMPLOYEE_PURCHASE',
}
