<div class="cabinet-header" [ngClass]="{ light: (1 | colourTheme) === false }">
    <!-- Logo Container -->
    <div class="cabinet-header__logo-wrapper logo-wrapper">
        <!-- <div (click)="openHomePage()" class="logo-wrapper__item logo">
        <div *ngIf="!showClanLogo" class="swarmLogo" [ngClass]="{light: (1 | colourTheme) === false}"></div>
        <svg *ngIf="showClanLogo" width="187" height="40" viewBox="0 0 187 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7137 15.3876C12.5641 16.9766 12.0121 18.1983 11.0577 19.0528C10.1033 19.9003 8.80697 20.324 7.1688 20.324C6.06481 20.324 5.08546 20.0486 4.23076 19.4977C3.38318 18.9398 2.72435 18.1524 2.25426 17.1355C1.78418 16.1114 1.54557 14.9427 1.53845 13.6291V11.2456C1.53845 9.93207 1.76993 8.75975 2.2329 7.72868C2.69586 6.69055 3.35469 5.88899 4.20939 5.32402C5.07122 4.75905 6.05768 4.47656 7.1688 4.47656C8.79273 4.47656 10.0855 4.90029 11.047 5.74775C12.0085 6.59521 12.5641 7.8099 12.7137 9.39182H12.1367C11.9729 7.97233 11.4744 6.89535 10.641 6.16088C9.80768 5.41936 8.65028 5.0486 7.1688 5.0486C5.65882 5.0486 4.43731 5.61357 3.50426 6.74351C2.57834 7.87346 2.11538 9.38829 2.11538 11.288V13.555C2.11538 14.7696 2.32193 15.8466 2.73503 16.7859C3.14814 17.7181 3.73931 18.4455 4.50854 18.9681C5.28489 19.4907 6.17164 19.752 7.1688 19.752C8.6574 19.752 9.81837 19.3883 10.6517 18.6609C11.4922 17.9335 11.9872 16.8424 12.1367 15.3876H12.7137Z" fill="currentColor"/>
            <path d="M17.1312 19.5401H25.112V20.1122H16.5436V4.68843H17.1312V19.5401Z" fill="currentColor"/>
            <path d="M36.4739 15.5041H28.8564L27.1256 20.1122H26.4953L32.3179 4.68843H33.0231L38.8457 20.1122H38.2154L36.4739 15.5041ZM29.0701 14.9321H36.2603L32.6705 5.37699L29.0701 14.9321Z" fill="currentColor"/>
            <path d="M52.7825 20.1122H52.2056L42.1628 5.663V20.1122H41.5752V4.68843H42.1628L52.2056 19.1058V4.68843H52.7825V20.1122Z" fill="currentColor"/>
            <path d="M59.9457 20.1122H56.7406V4.68843H59.9457V20.1122Z" fill="currentColor"/>
            <path d="M75.7949 20.1122H72.5897L66.3504 9.96385V20.1122H63.1453V4.68843H66.3504L72.6004 14.8579V4.68843H75.7949V20.1122Z" fill="currentColor"/>
            <path d="M88.1718 13.8092H82.018V20.1122H78.8128V4.68843H88.941V7.26258H82.018V11.2456H88.1718V13.8092Z" fill="currentColor"/>
            <path d="M103.797 12.7499C103.797 14.2682 103.526 15.5994 102.985 16.7435C102.443 17.8876 101.667 18.7703 100.656 19.3918C99.6513 20.0133 98.4975 20.324 97.194 20.324C95.9049 20.324 94.7546 20.0168 93.7432 19.4024C92.7318 18.788 91.9483 17.9123 91.3927 16.7753C90.8372 15.6312 90.5559 14.3177 90.5487 12.8346V12.0719C90.5487 10.5535 90.8229 9.21879 91.3714 8.06766C91.9269 6.90947 92.7068 6.02317 93.7111 5.40877C94.7225 4.7873 95.8764 4.47656 97.1727 4.47656C98.469 4.47656 99.6192 4.7873 100.624 5.40877C101.635 6.02317 102.415 6.90947 102.963 8.06766C103.519 9.21879 103.797 10.55 103.797 12.0613V12.7499ZM100.549 12.0507C100.549 10.4335 100.257 9.20467 99.6727 8.36427C99.0886 7.52388 98.2553 7.10368 97.1727 7.10368C96.0972 7.10368 95.2674 7.52035 94.6833 8.35368C94.0993 9.17995 93.8037 10.3946 93.7966 11.9977V12.7499C93.7966 14.3247 94.0886 15.5465 94.6727 16.4151C95.2567 17.2838 96.0972 17.7181 97.194 17.7181C98.2695 17.7181 99.0957 17.3014 99.6727 16.4681C100.25 15.6277 100.542 14.4059 100.549 12.8028V12.0507Z" fill="currentColor"/>
            <path d="M112.103 14.466H109.55V20.1122H106.344V4.68843H112.124C113.962 4.68843 115.379 5.0945 116.377 5.90665C117.374 6.71879 117.872 7.86639 117.872 9.34944C117.872 10.4017 117.641 11.2809 117.178 11.9872C116.722 12.6863 116.028 13.2442 115.094 13.6609L118.46 19.9639V20.1122H115.02L112.103 14.466ZM109.55 11.8918H112.135C112.94 11.8918 113.563 11.6905 114.005 11.288C114.446 10.8784 114.667 10.317 114.667 9.60368C114.667 8.87628 114.457 8.30425 114.037 7.88758C113.624 7.47091 112.986 7.26258 112.124 7.26258H109.55V11.8918Z" fill="currentColor"/>
            <path d="M124.726 4.68843L128.721 15.8749L132.696 4.68843H136.905V20.1122H133.689V15.8961L134.01 8.61851L129.811 20.1122H127.61L123.422 8.6291L123.743 15.8961V20.1122H120.538V4.68843H124.726Z" fill="currentColor"/>
            <path d="M148.897 16.9342H143.278L142.209 20.1122H138.801L144.592 4.68843H147.562L153.385 20.1122H149.977L148.897 16.9342ZM144.143 14.36H148.032L146.077 8.58673L144.143 14.36Z" fill="currentColor"/>
            <path d="M165.484 7.26258H160.719V20.1122H157.514V7.26258H152.813V4.68843H165.484V7.26258Z" fill="currentColor"/>
            <path d="M170.948 20.1122H167.743V4.68843H170.948V20.1122Z" fill="currentColor"/>
            <path d="M179.02 13.9257L177.353 15.7054V20.1122H174.148V4.68843H177.353V11.68L178.763 9.76258L182.727 4.68843H186.669L181.146 11.5422L186.83 20.1122H183.015L179.02 13.9257Z" fill="currentColor"/>
            <path d="M45.8965 37.0626V28.2261H48.2408C49.1997 28.2261 49.924 28.4203 50.4137 28.8087C50.9075 29.1931 51.1543 29.7535 51.1543 30.4899C51.1543 31.0239 51.0176 31.467 50.7442 31.819C50.4708 32.171 50.0852 32.3955 49.5874 32.4926C50.1505 32.5655 50.6198 32.8082 50.9952 33.2209C51.3706 33.6296 51.5583 34.0989 51.5583 34.6289C51.5583 35.3815 51.3114 35.9763 50.8177 36.4132C50.328 36.8462 49.6445 37.0626 48.7672 37.0626H45.8965ZM46.227 32.699V36.7349H48.8039C49.5588 36.7349 50.1505 36.5508 50.579 36.1826C51.0115 35.8104 51.2278 35.2965 51.2278 34.6411C51.2278 34.0827 51.0197 33.6235 50.6035 33.2634C50.1872 32.8993 49.6302 32.7111 48.9325 32.699H46.227ZM46.227 32.3713H48.3632C49.1752 32.3713 49.7873 32.2175 50.1995 31.91C50.6116 31.5985 50.8177 31.117 50.8177 30.4656C50.8177 29.2113 49.9832 28.5741 48.3142 28.5538H46.227V32.3713Z" fill="currentColor"/>
            <path d="M58.3861 32.6808H53.9178V36.7349H59.0104V37.0626H53.5873V28.2261H59.0104V28.5538H53.9178V32.3531H58.3861V32.6808Z" fill="currentColor"/>
            <path d="M66.187 34.9445C66.187 34.5683 66.0973 34.2506 65.9177 33.9917C65.7423 33.7287 65.4627 33.4981 65.0792 33.2998C64.6997 33.0975 64.1243 32.8912 63.3531 32.6808C62.5818 32.4704 62.0024 32.258 61.6147 32.0435C61.2311 31.8291 60.9496 31.5803 60.77 31.297C60.5905 31.0138 60.5007 30.6699 60.5007 30.2653C60.5007 29.622 60.7741 29.1021 61.3209 28.7056C61.8677 28.305 62.5798 28.1047 63.4571 28.1047C64.0284 28.1047 64.5405 28.216 64.9935 28.4385C65.4505 28.657 65.8035 28.9665 66.0524 29.3671C66.3013 29.7636 66.4258 30.2127 66.4258 30.7144H66.0952C66.0952 30.0549 65.8504 29.5107 65.3607 29.0819C64.871 28.6489 64.2365 28.4325 63.4571 28.4325C62.6736 28.4325 62.0391 28.6064 61.5535 28.9544C61.072 29.2983 60.8312 29.7312 60.8312 30.2532C60.8312 30.7832 61.0414 31.2101 61.4617 31.5337C61.882 31.8574 62.5757 32.1447 63.5428 32.3955C64.5099 32.6423 65.2179 32.9296 65.6668 33.2573C66.238 33.6741 66.5237 34.2324 66.5237 34.9324C66.5237 35.3775 66.3972 35.7719 66.1442 36.1159C65.8912 36.4557 65.5321 36.7187 65.0669 36.9048C64.6017 37.0909 64.0794 37.184 63.5 37.184C62.8552 37.184 62.2758 37.0788 61.7616 36.8684C61.2475 36.654 60.8598 36.3505 60.5986 35.9581C60.3416 35.5656 60.213 35.1023 60.213 34.5683H60.5436C60.5436 35.2925 60.817 35.8549 61.3638 36.2554C61.9106 36.656 62.6226 36.8563 63.5 36.8563C64.2794 36.8563 64.922 36.6803 65.428 36.3283C65.934 35.9722 66.187 35.511 66.187 34.9445Z" fill="currentColor"/>
            <path d="M73.7309 34.9445C73.7309 34.5683 73.6412 34.2506 73.4616 33.9917C73.2861 33.7287 73.0066 33.4981 72.623 33.2998C72.2435 33.0975 71.6682 32.8912 70.8969 32.6808C70.1257 32.4704 69.5463 32.258 69.1586 32.0435C68.775 31.8291 68.4935 31.5803 68.3139 31.297C68.1344 31.0138 68.0446 30.6699 68.0446 30.2653C68.0446 29.622 68.318 29.1021 68.8648 28.7056C69.4116 28.305 70.1237 28.1047 71.001 28.1047C71.5723 28.1047 72.0844 28.216 72.5374 28.4385C72.9944 28.657 73.3474 28.9665 73.5963 29.3671C73.8452 29.7636 73.9696 30.2127 73.9696 30.7144H73.6391C73.6391 30.0549 73.3943 29.5107 72.9046 29.0819C72.4149 28.6489 71.7804 28.4325 71.001 28.4325C70.2175 28.4325 69.583 28.6064 69.0974 28.9544C68.6159 29.2983 68.3751 29.7312 68.3751 30.2532C68.3751 30.7832 68.5853 31.2101 69.0056 31.5337C69.4259 31.8574 70.1196 32.1447 71.0867 32.3955C72.0538 32.6423 72.7618 32.9296 73.2107 33.2573C73.7819 33.6741 74.0676 34.2324 74.0676 34.9324C74.0676 35.3775 73.9411 35.7719 73.6881 36.1159C73.4351 36.4557 73.076 36.7187 72.6108 36.9048C72.1456 37.0909 71.6233 37.184 71.0439 37.184C70.3991 37.184 69.8197 37.0788 69.3055 36.8684C68.7914 36.654 68.4037 36.3505 68.1425 35.9581C67.8855 35.5656 67.7569 35.1023 67.7569 34.5683H68.0875C68.0875 35.2925 68.3609 35.8549 68.9077 36.2554C69.4545 36.656 70.1665 36.8563 71.0439 36.8563C71.8232 36.8563 72.4659 36.6803 72.9719 36.3283C73.4779 35.9722 73.7309 35.511 73.7309 34.9445Z" fill="currentColor"/>
            <path d="M80.7362 32.6808H76.2679V36.7349H81.3605V37.0626H75.9374V28.2261H81.3605V28.5538H76.2679V32.3531H80.7362V32.6808Z" fill="currentColor"/>
            <path d="M86.5907 33.2816H83.4996V37.0626H83.1691V28.2261H86.0092C86.9029 28.2261 87.6129 28.4547 88.1393 28.9119C88.6698 29.3691 88.935 29.9902 88.935 30.7751C88.935 31.378 88.7453 31.906 88.3658 32.3591C87.9903 32.8082 87.5109 33.0874 86.9274 33.1967L89.3084 36.9777V37.0626H88.9595L86.5907 33.2816ZM83.4996 32.9539H86.3091C86.9947 32.9539 87.5476 32.7475 87.9679 32.3349C88.3923 31.9222 88.6045 31.4022 88.6045 30.7751C88.6045 30.0873 88.3698 29.5451 87.9006 29.1486C87.4354 28.7521 86.7968 28.5538 85.9847 28.5538H83.4996V32.9539Z" fill="currentColor"/>
            <path d="M94.3917 34.8717L96.4116 28.2261H98.456L95.3527 37.0626H93.4368L90.3458 28.2261H92.384L94.3917 34.8717Z" fill="currentColor"/>
            <path d="M106.661 32.8446C106.661 33.7145 106.506 34.4772 106.196 35.1327C105.886 35.7881 105.441 36.2939 104.861 36.6499C104.286 37.006 103.625 37.184 102.878 37.184C102.14 37.184 101.481 37.008 100.901 36.656C100.322 36.304 99.8728 35.8023 99.5545 35.1509C99.2362 34.4954 99.0751 33.7429 99.071 32.8932V32.4562C99.071 31.5863 99.2281 30.8216 99.5423 30.1621C99.8606 29.4986 100.307 28.9908 100.883 28.6388C101.462 28.2828 102.123 28.1047 102.866 28.1047C103.609 28.1047 104.268 28.2828 104.843 28.6388C105.422 28.9908 105.869 29.4986 106.183 30.1621C106.502 30.8216 106.661 31.5843 106.661 32.4502V32.8446ZM104.8 32.4441C104.8 31.5176 104.633 30.8135 104.298 30.3321C103.964 29.8506 103.486 29.6099 102.866 29.6099C102.25 29.6099 101.774 29.8486 101.44 30.326C101.105 30.7994 100.936 31.4953 100.932 32.4137V32.8446C100.932 33.7469 101.099 34.4469 101.434 34.9445C101.768 35.4422 102.25 35.691 102.878 35.691C103.494 35.691 103.968 35.4523 104.298 34.9749C104.629 34.4934 104.796 33.7934 104.8 32.875V32.4441Z" fill="currentColor"/>
            <path d="M111.42 33.8278H109.957V37.0626H108.121V28.2261H111.432C112.485 28.2261 113.297 28.4588 113.868 28.9241C114.439 29.3893 114.725 30.0468 114.725 30.8965C114.725 31.4993 114.592 32.0031 114.327 32.4077C114.066 32.8082 113.668 33.1279 113.134 33.3666L115.062 36.9777V37.0626H113.091L111.42 33.8278ZM109.957 32.3531H111.438C111.899 32.3531 112.256 32.2377 112.509 32.0071C112.762 31.7725 112.889 31.4508 112.889 31.0421C112.889 30.6254 112.768 30.2977 112.528 30.059C112.291 29.8202 111.926 29.7009 111.432 29.7009H109.957V32.3531Z" fill="currentColor"/>
            <path d="M121.375 34.4226H117.011L116.02 37.0626H115.658L118.994 28.2261H119.398L122.734 37.0626H122.373L121.375 34.4226ZM117.134 34.0949H121.253L119.196 28.6206L117.134 34.0949Z" fill="currentColor"/>
            <path d="M124.635 36.7349H129.207V37.0626H124.298V28.2261H124.635V36.7349Z" fill="currentColor"/>
            <path d="M136.237 34.9445C136.237 34.5683 136.147 34.2506 135.967 33.9917C135.792 33.7287 135.512 33.4981 135.129 33.2998C134.749 33.0975 134.174 32.8912 133.403 32.6808C132.631 32.4704 132.052 32.258 131.664 32.0435C131.281 31.8291 130.999 31.5803 130.82 31.297C130.64 31.0138 130.55 30.6699 130.55 30.2653C130.55 29.622 130.824 29.1021 131.37 28.7056C131.917 28.305 132.629 28.1047 133.507 28.1047C134.078 28.1047 134.59 28.216 135.043 28.4385C135.5 28.657 135.853 28.9665 136.102 29.3671C136.351 29.7636 136.475 30.2127 136.475 30.7144H136.145C136.145 30.0549 135.9 29.5107 135.41 29.0819C134.921 28.6489 134.286 28.4325 133.507 28.4325C132.723 28.4325 132.089 28.6064 131.603 28.9544C131.122 29.2983 130.881 29.7312 130.881 30.2532C130.881 30.7832 131.091 31.2101 131.511 31.5337C131.932 31.8574 132.625 32.1447 133.592 32.3955C134.559 32.6423 135.267 32.9296 135.716 33.2573C136.288 33.6741 136.573 34.2324 136.573 34.9324C136.573 35.3775 136.447 35.7719 136.194 36.1159C135.941 36.4557 135.582 36.7187 135.116 36.9048C134.651 37.0909 134.129 37.184 133.55 37.184C132.905 37.184 132.325 37.0788 131.811 36.8684C131.297 36.654 130.909 36.3505 130.648 35.9581C130.391 35.5656 130.263 35.1023 130.263 34.5683H130.593C130.593 35.2925 130.867 35.8549 131.413 36.2554C131.96 36.656 132.672 36.8563 133.55 36.8563C134.329 36.8563 134.972 36.6803 135.478 36.3283C135.984 35.9722 136.237 35.511 136.237 34.9445Z" fill="currentColor"/>
            <path d="M142.636 34.7443C142.636 34.4003 142.513 34.1374 142.269 33.9553C142.024 33.7692 141.583 33.575 140.947 33.3727C140.31 33.1663 139.806 32.964 139.435 32.7658C138.423 32.2236 137.917 31.4933 137.917 30.5748C137.917 30.0974 138.051 29.6726 138.321 29.3003C138.594 28.9241 138.984 28.6307 139.49 28.4203C140 28.2099 140.571 28.1047 141.204 28.1047C141.84 28.1047 142.407 28.22 142.905 28.4507C143.403 28.6772 143.789 28.9989 144.062 29.4156C144.34 29.8324 144.478 30.3058 144.478 30.8358H142.642C142.642 30.4312 142.513 30.1176 142.256 29.8951C141.999 29.6685 141.638 29.5552 141.173 29.5552C140.724 29.5552 140.375 29.6503 140.126 29.8405C139.877 30.0266 139.753 30.2734 139.753 30.5809C139.753 30.8682 139.898 31.1089 140.188 31.3031C140.481 31.4973 140.912 31.6794 141.479 31.8493C142.524 32.1609 143.285 32.5473 143.762 33.0085C144.24 33.4698 144.478 34.0443 144.478 34.7321C144.478 35.4968 144.187 36.0976 143.603 36.5346C143.019 36.9675 142.234 37.184 141.246 37.184C140.561 37.184 139.937 37.0606 139.373 36.8138C138.81 36.5629 138.38 36.2211 138.082 35.7881C137.788 35.3552 137.641 34.8535 137.641 34.283H139.484C139.484 35.2581 140.071 35.7456 141.246 35.7456C141.683 35.7456 142.024 35.6587 142.269 35.4847C142.513 35.3067 142.636 35.0598 142.636 34.7443Z" fill="currentColor"/>
            <path d="M153.173 32.8446C153.173 33.7145 153.018 34.4772 152.708 35.1327C152.398 35.7881 151.953 36.2939 151.373 36.6499C150.798 37.006 150.137 37.184 149.39 37.184C148.652 37.184 147.993 37.008 147.413 36.656C146.834 36.304 146.385 35.8023 146.067 35.1509C145.748 34.4954 145.587 33.7429 145.583 32.8932V32.4562C145.583 31.5863 145.74 30.8216 146.054 30.1621C146.373 29.4986 146.819 28.9908 147.395 28.6388C147.974 28.2828 148.635 28.1047 149.378 28.1047C150.121 28.1047 150.78 28.2828 151.355 28.6388C151.934 28.9908 152.381 29.4986 152.695 30.1621C153.014 30.8216 153.173 31.5843 153.173 32.4502V32.8446ZM151.312 32.4441C151.312 31.5176 151.145 30.8135 150.81 30.3321C150.476 29.8506 149.998 29.6099 149.378 29.6099C148.762 29.6099 148.286 29.8486 147.952 30.326C147.617 30.7994 147.448 31.4953 147.444 32.4137V32.8446C147.444 33.7469 147.611 34.4469 147.946 34.9445C148.28 35.4422 148.762 35.691 149.39 35.691C150.006 35.691 150.48 35.4523 150.81 34.9749C151.141 34.4934 151.308 33.7934 151.312 32.875V32.4441Z" fill="currentColor"/>
            <path d="M157.932 33.8278H156.469V37.0626H154.633V28.2261H157.944C158.997 28.2261 159.809 28.4588 160.38 28.9241C160.951 29.3893 161.237 30.0468 161.237 30.8965C161.237 31.4993 161.104 32.0031 160.839 32.4077C160.578 32.8082 160.18 33.1279 159.646 33.3666L161.574 36.9777V37.0626H159.603L157.932 33.8278ZM156.469 32.3531H157.95C158.411 32.3531 158.768 32.2377 159.021 32.0071C159.274 31.7725 159.401 31.4508 159.401 31.0421C159.401 30.6254 159.28 30.2977 159.04 30.059C158.803 29.8202 158.438 29.7009 157.944 29.7009H156.469V32.3531Z" fill="currentColor"/>
            <path d="M169.815 35.9459C169.485 36.3384 169.018 36.6439 168.414 36.8623C167.81 37.0768 167.141 37.184 166.406 37.184C165.635 37.184 164.957 37.0181 164.374 36.6863C163.794 36.3505 163.346 35.865 163.027 35.2298C162.713 34.5946 162.552 33.8481 162.544 32.9903V32.3895C162.544 31.5074 162.693 30.7448 162.991 30.1014C163.293 29.4541 163.725 28.9605 164.288 28.6206C164.855 28.2767 165.519 28.1047 166.278 28.1047C167.334 28.1047 168.161 28.3556 168.756 28.8573C169.352 29.355 169.705 30.0812 169.815 31.0361H168.028C167.946 30.5303 167.765 30.1601 167.483 29.9254C167.206 29.6908 166.822 29.5734 166.333 29.5734C165.708 29.5734 165.233 29.8061 164.906 30.2714C164.58 30.7367 164.415 31.4285 164.411 32.347V32.9114C164.411 33.8379 164.588 34.5379 164.943 35.0113C165.298 35.4847 165.818 35.7214 166.504 35.7214C167.194 35.7214 167.685 35.5757 167.979 35.2844V33.7611H166.308V32.4198H169.815V35.9459Z" fill="currentColor"/>
            <path d="M176.796 33.2331H173.271V35.6H177.408V37.0626H171.434V28.2261H177.396V29.7009H173.271V31.8068H176.796V33.2331Z" fill="currentColor"/>
            <path d="M185.858 37.0626H184.022L180.447 31.2485V37.0626H178.611V28.2261H180.447L184.028 34.0524V28.2261H185.858V37.0626Z" fill="currentColor"/>
        </svg>
    </div> -->
    </div>
    <div class="cabinet-header__user-wrapper">
        <app-user-header [userName]="userName" [userSsn]="ssn"></app-user-header>
    </div>
</div>
