import { Component, Output, EventEmitter, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'app-switcher',
    animations: [
        trigger('switchColor', [
            state(
                'off',
                style({
                    background: '#2A3C73',
                })
            ),
            state(
                'on',
                style({
                    background: '#5BA4C8',
                })
            ),
            transition('black => on', [animate('0.5s')]),
            transition('white => off', [animate('0.5s')]),
        ]),
    ],
    templateUrl: './switcher.component.html',
    styleUrls: ['./switcher.component.scss'],
})
export class SwitcherComponent {
    switcherState = true; // true for black, false for white
    @Output() switcherChange: EventEmitter<boolean> = new EventEmitter();
    @Input() opposite = false;
    @Input() simpleIcon = false;

    toggleSwicther() {
        this.switcherState = !this.switcherState;
        this.switcherChange.emit(this.switcherState);
    }
}
