import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-named-datepicker',
    templateUrl: './named-datapicker.component.html',
    styleUrls: ['./named-datapicker.component.scss'],
})
export class NamedDatepickerComponent implements OnInit {
    @Output() dateChanged: EventEmitter<Date> = new EventEmitter();
    @Input() type: 'input' | 'icon' = 'icon';
    @Input() currentDate: Date = new Date();
    @Input() isBirth = false;
    @Input() title = '';
    @Input() disabled!: boolean;
    @Input() maxDate!: Date;
    @Input() minDate!: Date;
    @Input() dateFilter?: (date: Date | null) => boolean;
    formControl: FormControl;
    currentValue: Date = new Date();

    constructor() {}

    ngOnInit(): void {
        // Initialize the form control
        this.formControl = new FormControl({ 
            value: this.currentDate || new Date(), 
            disabled: this.disabled 
        });

        // Set initial value
        this.currentValue = this.currentDate || new Date();
        
        // Subscribe to value changes
        this.formControl.valueChanges.subscribe((value) => {
            if (!value) return;
            
            const selectedDate = new Date(value);
            
            // Only update if the date passes the filter
            if (!this.dateFilter || this.dateFilter(selectedDate)) {
                this.currentValue = selectedDate;
                this.dateChanged.emit(selectedDate);
            }
        });
    }

    reformateDate(dt) {
        return !dt
            ? ''
            : (dt.getDate() > 9 ? dt.getDate() : '0' + dt.getDate()) +
                  '.' +
                  (dt.getMonth() > 8 ? dt.getMonth() + 1 : '0' + (dt.getMonth() + 1)) +
                  '.' +
                  dt.getFullYear();
    }
}
