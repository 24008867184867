import { VoteMeterComponent } from './components/vote-meter/vote-meter.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommonButtonComponent } from './components/common-button/common-button.component';
import { CommonDocumentComponent } from './components/common-document/common-document.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { FeedCardComponent } from './components/feed-card/feed-card.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { LikeButtonComponent } from './components/like-button/like-button.component';
import { MoreButtonComponent } from './components/more-button/more-button.component';
import { PensionCalcGraphComponent } from './components/pension-calc-graph/pension-calc-graph.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { TypeChipComponent } from './components/type-chip/type-chip.component';
import { VoteCardComponent } from './components/vote-card/vote-card.component';
import { AmountLineComponent } from './components/amount-line/amount-line.component';
import { ColourThemePipe } from './pipes/colour-theme.pipe';
import { CurrencyVisibilityPipe } from './pipes/currency-visibility.pipe';
import { SplitNumberWithCommaPipe } from './pipes/split-number-with-comma.pipe';
import { SplitPhonePipe } from './pipes/split-phone.pipe';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NamedDatepickerComponent } from './components/named-datapicker/named-datapicker.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { searchMutationspipe } from './pipes/searchMutations.pipe';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { TranslateEnumPipe } from '../modules/ak/shared/pipes/translate-enum.pipe';
import { AkProfileChartComponent } from './components/ak-profile-chart/ak-profile-chart.component';
import { MoneyPipe } from './pipes/money.pipe';

@NgModule({
    declarations: [
        SwitcherComponent,
        DatepickerComponent,
        MoreButtonComponent,
        PensionCalcGraphComponent,
        LikeButtonComponent,
        TypeChipComponent,
        FeedCardComponent,
        VoteCardComponent,
        VoteMeterComponent,
        CommonButtonComponent,
        CheckboxComponent,
        CommonDocumentComponent,
        CurrencyVisibilityPipe,
        searchMutationspipe,
        ColourThemePipe,
        SplitNumberWithCommaPipe,
        SplitPhonePipe,
        NamedDatepickerComponent,
        AmountLineComponent,
        AkProfileChartComponent,
        FormInputComponent,
        DropdownComponent,
        PopUpComponent,
        PaginatorComponent,
        PreloaderComponent,
        TranslateEnumPipe,
        MoneyPipe,
    ],
    imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, MaterialModule],
    exports: [
        SwitcherComponent,
        DatepickerComponent,
        MoreButtonComponent,
        PensionCalcGraphComponent,
        LikeButtonComponent,
        TypeChipComponent,
        FeedCardComponent,
        FormInputComponent,
        VoteCardComponent,
        VoteMeterComponent,
        CommonButtonComponent,
        searchMutationspipe,
        CheckboxComponent,
        CommonDocumentComponent,
        CurrencyVisibilityPipe,
        ColourThemePipe,
        SplitNumberWithCommaPipe,
        SplitPhonePipe,
        NamedDatepickerComponent,
        AmountLineComponent,
        AkProfileChartComponent,
        DropdownComponent,
        PopUpComponent,
        PaginatorComponent,
        PreloaderComponent,
        TranslateEnumPipe,
        MoneyPipe,
    ],
    providers: [],
})
export class SharedModule {}
