<div
    class="document"
    [ngClass]="{
        'doc-small': documentType === 'small',
        big: documentType === 'big',
        light: (1 | colourTheme) === false
    }"
>
    <div class="document-wrapper">
        <div class="document-content">
            <div class="document-title" [ngClass]="{ light: (1 | colourTheme) === false }">
                {{ document.name }}
            </div>
            <div class="thumbnail-container">
                <div class="document-thumbnail">
                    <img [src]="thumbnailSrc" class="image" alt="DocumentThumbnail" />
                </div>
            </div>
            <div class="document-actions">
                <div
                    (click)="downloadButtonClick()"
                    *ngIf="documentType === 'big'"
                    class="action-button action-button--big"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    {{ 'cabinet.myDocuments.download' | translate }}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19 12V19H5V12H3V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V12H19ZM13 12.67L15.59 10.09L17 11.5L12 16.5L7 11.5L8.41 10.09L11 12.67V3H13V12.67Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
                <div
                    (click)="downloadButtonClick()"
                    *ngIf="documentType === 'small'"
                    class="action-button action-button--small"
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.6667 8V12.6667H3.33333V8H2V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667ZM8.66667 8.44667L10.3933 6.72667L11.3333 7.66667L8 11L4.66667 7.66667L5.60667 6.72667L7.33333 8.44667V2H8.66667V8.44667Z"
                            fill="var(--primary)"
                        />
                    </svg>
                    {{ 'cabinet.myDocuments.download' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
