import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Assignee } from '../models/Transactions/Assignee';
import PurchaseTransaction from '../models/Transactions/PurchaseTransaction';
import Transaction, { Originator } from '../models/Transactions/Transaction';
import { TransactionStatus } from '../models/Transactions/TransactionStatus';
import { TransactionType } from '../models/Transactions/TransactionType';
import { LocaleService } from './locale.service';

@Injectable({
    providedIn: 'root',
})
export class TransactionsService {
    private ongoingPurchaseTransactions: ReplaySubject<PurchaseTransaction[][]> = new ReplaySubject<
        PurchaseTransaction[][]
    >(1);
    private finishedPurchaseTransactions: ReplaySubject<PurchaseTransaction[][]> = new ReplaySubject<
        PurchaseTransaction[][]
    >(1);

    private recentlyCreatedTransaction: ReplaySubject<PurchaseTransaction> = new ReplaySubject<PurchaseTransaction>(1);


    constructor(
        private http: HttpClient,
        private localeService: LocaleService,
        @Optional() @SkipSelf() parentModule?: TransactionsService
    ) {
        if (parentModule) {
            throw new Error('TransactionsService is already loaded. Import it in the AppModule only');
        }
    }

    recentlyCreatedTransactionUpdate(transaction:PurchaseTransaction) {
        this.recentlyCreatedTransaction.next(transaction);
    }

    RecentlyCreatedTransaction(): Observable<PurchaseTransaction> {
        return this.recentlyCreatedTransaction.asObservable();
    }

    get OngoingPurchaseTransactions(): Observable<PurchaseTransaction[][]> {
        return this.ongoingPurchaseTransactions.asObservable();
    }

    get FinishedPurchaseTransactions(): Observable<PurchaseTransaction[][]> {
        return this.finishedPurchaseTransactions.asObservable();
    }
    get apiUrl(): string {
        return sessionStorage.getItem('api-url');
    }
    getPurchaseTransactionsForPerson(personId: string) {
        return new Promise((resolve, reject) => {
            let url = this.createGetTransactionURL(personId, null, null, null, TransactionType.EMPLOYEE_PURCHASE);
            this.getPurchaseTransactions(url).subscribe(
                (transactions) => {
                    let finishedTransactions = transactions.filter(
                        (value) =>
                            // value[0].assignee == Assignee.NONE &&
                            value[0].status == TransactionStatus.DONE ||
                            value[0].status == TransactionStatus.CANCELLED ||
                            value[0].status == TransactionStatus.REJECTED
                    );
                    let ongoingTransactions = transactions.filter(
                        (transaction) => !finishedTransactions.includes(transaction)
                    );
                    this.finishedPurchaseTransactions.next(finishedTransactions);

                    this.ongoingPurchaseTransactions.next(ongoingTransactions);
                    resolve(true);
                },
                (error) => reject(false)
            );
        });
    }

    cancelPurchase(originator: Originator, transactionId: string, data: any, tenant, company) {
        let cancelTransaction = new PurchaseTransaction();
        cancelTransaction.originator = originator;
        cancelTransaction.transactionId = transactionId;
        cancelTransaction.status = TransactionStatus.CANCELLED;
        cancelTransaction.assignee = Assignee.PVCLAN;
        cancelTransaction.timestamp = Date.now().toString();
        cancelTransaction.tenant = tenant;
        cancelTransaction.company = company;
        //TODO: maybe we have some useful data later on to replace this
        cancelTransaction.data = data;
        return this.sendTransaction(cancelTransaction);
    }

    sendTransaction(transaction: Transaction) {
        return this.http.post(`${this.apiUrl}/transactions`, transaction);
    }

    private createGetTransactionURL(
        personId: string,
        companyId?: string,
        pensionFundId?: string,
        foundationId?: string,
        transactionType?: string,
        status?: string,
        assignee?: string
    ): string {
        let url = `${this.apiUrl}/transactions?person=${personId}`;

        if (companyId != null && companyId.length > 0) {
            url = url + `&companyId=${companyId}`;
        }
        if (pensionFundId != null && pensionFundId.length > 0) {
            url = url + `&pensionFund=${pensionFundId}`;
        }
        if (foundationId != null && foundationId.length > 0) {
            url = url + `&foundation=${foundationId}`;
        }
        if (transactionType != null && transactionType.length > 0) {
            url = url + `&type=${transactionType}`;
        }
        if (status != null && status.length > 0) {
            url = url + `&status=${status}`;
        }
        if (assignee != null && assignee.length > 0) {
            url = url + `&assignee=${assignee}`;
        }
        return url;
    }

    private getPurchaseTransactions(url: string) {
        return this.http.get<PurchaseTransaction[][]>(url);
    }
    public getTransactionById(transactionId: number) {
        return this.http.get<Transaction[]>(`${this.apiUrl}/transactions/${transactionId}`);
    }
}
