import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-steppable-page',
    templateUrl: './steppable-page.component.html',
    styleUrls: ['./steppable-page.component.scss'],
})
export class SteppablePageComponent implements OnInit {
    submitDisabled: boolean = false;
    stepElements: any[] = [];

    constructor() {}

    ngOnInit(): void {}

    activeStep(): any {
        return this.stepElements.find((element) => element.active === true);
    }

    private resetFlowSteps(): void {
        this.stepElements.forEach((value) => {
            value.passed = false;
            value.active = false;
        });
    }

    activeStepName(): string {
        return this.stepElements.find((element) => element.active === true).name;
    }

    activeStepTitle(): string {
        return this.stepElements.find((element) => element.active === true).title;
    }

    goToStep(target: number) {
        this.resetFlowSteps();
        for (let i = 0; i < target; i++) {
            this.stepElements[i].passed = true;
        }
        this.stepElements[target].active = true;
        // this.auth.resetActivity()
    }

    backToEdit() {
        this.goToStep(0);
        this.submitDisabled = false;
    }
}
