import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFeedCard } from 'src/app/modules/home/services/my-swarm-feed.service';

@Component({
    selector: 'app-feed-card',
    templateUrl: './feed-card.component.html',
    styleUrls: ['./feed-card.component.scss'],
})
export class FeedCardComponent implements OnInit {
    @Input() feedCard: IFeedCard;

    @Output() voteCard = new EventEmitter<IFeedCard>();

    @Output() addToFavorite = new EventEmitter<string>();

    voteListVisibility = false;

    constructor() {}

    voteButtonClick(feedCard: IFeedCard): void {
        this.voteCard.emit(feedCard);
        this.toggleVoteListVisibility(true);
    }

    toggleVoteListVisibility(value): void {
        this.voteListVisibility = value;
    }

    addCardToFavorite(feedCardTitle: string): void {
        this.addToFavorite.emit(feedCardTitle);
    }

    ngOnInit(): void {}
}
