import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrencyService } from '../../../../core/services/currency.service';
import { AuthenticationService } from 'clan-auth';
import { UserService } from '../../../../core/services/user.service';

@Component({
    selector: 'app-user-header',
    templateUrl: './user-header.component.html',
    styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements AfterViewInit, OnInit {
    @Input() userName: string;
    @Input() userSsn: string;
    initials;
    firstNameFirstLetter;
    lastNameFirstLetter;
    constructor(
        private router: Router,
        private currencyService: CurrencyService,
        private authService: AuthenticationService,
        private currentUserService: UserService
    ) {
        this.currentUserService.$userData.subscribe((data) => {
            this.userName = data.firstName + ' ' + data.lastName;
        });
    }

    ngOnInit() {
        // 'ngOnInit' is empty
    }

    ngAfterViewInit(): void {
        // 'ngAfterViewInit' is empty
    }

    ngOnChanges(changes: any): void {
        if (this.userName != undefined) {
            this.initials = this.userName.split(' ');
            this.firstNameFirstLetter = this.initials[0].charAt(0);
            this.lastNameFirstLetter = this.initials[1].charAt(0);
            this.initials = this.firstNameFirstLetter + '. ' + this.lastNameFirstLetter + '.';
        }
    }

    get $currencyVisibility(): Observable<boolean> {
        return this.currencyService.$currencyValuesVisibility;
    }

    toggleCurrencyValueVisibility() {
        this.currencyService.toggleCurrencyValuesVisibility();
    }

    doLogout() {
        const redirectUrl = sessionStorage.getItem('default_tenant_login_url');
        this.authService.logout().subscribe(() => {
            this.router.navigateByUrl(`auth${redirectUrl}`);
        });
    }

    async openFagPage() {
        await this.router.navigate(['/cabinet/faq']);
    }

    async openCommunicationCenterPage() {
        await this.router.navigate(['/cabinet/communication-center']);
    }
}
