import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data';

export class Content {
    id?: string;
    title: {
        de: string;
        en: string;
        fr: string;
        it: string;
    };
    content: {
        de: OutputData;
        en: OutputData;
        fr: OutputData;
        it: OutputData;
    };
    author: string;
    date: string;
    contentType: ContentType;
    renderEngine: string;
    createdAt: string;
    image: Record<string, any>;
    attachments: any[];
    status: ContentStatus;
    locationId: number;
    user: any;
    pensionFund: any;
    company: any;
    foundation: any;
    imageThumbnail: any;
    targetGroup: string;

    constructor() {
        this.id = null;
        this.title = {
            de: '',
            en: '',
            fr: '',
            it: '',
        };
        this.content = {
            de: null,
            en: null,
            fr: null,
            it: null,
        };
        this.date = null;
        this.author = null;
        this.renderEngine = null;
        this.createdAt = null;
        this.image = null;
        this.attachments = [];
        this.status = null;
        this.locationId = null;
    }
}

export enum ContentType {
    PAGE = 'PAGE',
    POLL = 'POLL',
    NEWS = 'NEWS',
}

export enum ContentStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
}

export enum ContentTargetGroup {
    AK = 'AK',
    PK = 'PK',
}
