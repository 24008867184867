import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'money',
})
export class MoneyPipe implements PipeTransform {
    transform(value: number | string): number {
        if (typeof value === 'string') {
            value = parseFloat(value.replace(/'/g, '').replace(/,/g, ''));
        }

        if (isNaN(value)) return 0;

        const roundedValue = Math.round(value * 100) / 100;
        const decimalPart = (roundedValue * 100) % 100;

        if (decimalPart % 5 !== 0) {
            const adjustedValue = Math.round(decimalPart / 5) * 5;
            return Math.floor(roundedValue) + adjustedValue / 100;
        }

        return roundedValue;
    }
}
