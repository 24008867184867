<div
    class="type-chip"
    [ngClass]="{
        'type-chip--white': type === 'all',
        'type-chip--red': type === 'vote',
        'type-chip--green': type === 'notification',
        'type-chip--yellow': type === 'verification',
        'type-chip--white--active': selected && type === 'all',
        'type-chip--red--active': selected && type === 'vote',
        'type-chip--green--active': selected && type === 'notification',
        'type-chip--yellow--active': selected && type === 'verification',
        light: (1 | colourTheme) === false
    }"
    (click)="select()"
>
    <svg
        *ngIf="type === 'vote'"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.00001 1.8335C4.32001 1.8335 1.33334 4.82016 1.33334 8.50016C1.33334 12.1802 4.32001 15.1668 8.00001 15.1668C11.68 15.1668 14.6667 12.1802 14.6667 8.50016C14.6667 4.82016 11.68 1.8335 8.00001 1.8335ZM6.66668 11.8335L3.33334 8.50016L4.27334 7.56016L6.66668 9.94683L11.7267 4.88683L12.6667 5.8335L6.66668 11.8335Z"
            fill="#E13959"
        />
    </svg>
    <svg
        *ngIf="type === 'notification'"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.4286 5.00001H12.5714V1.57144C12.5714 1.25584 12.3156 1 12 1H0.571439C0.255837 1 0 1.25584 0 1.57144V14.7143C0 15.9767 1.02335 17 2.28572 17H13.7143C14.9767 17 16 15.9767 16 14.7143V5.57141C16 5.25585 15.7442 5.00001 15.4286 5.00001ZM4.57141 3.85713H7.99998C8.31559 3.85713 8.57142 4.11297 8.57142 4.42857C8.57142 4.74417 8.31559 5.00001 7.99998 5.00001H4.57141C4.25581 5.00001 3.99997 4.74417 3.99997 4.42857C3.99997 4.11297 4.25585 3.85713 4.57141 3.85713ZM9.71427 14.7143H2.85713C2.54153 14.7143 2.28569 14.4584 2.28569 14.1428C2.28569 13.8272 2.54153 13.5714 2.85713 13.5714H9.71427C10.0299 13.5714 10.2857 13.8272 10.2857 14.1428C10.2857 14.4584 10.0299 14.7143 9.71427 14.7143ZM9.71427 12.4286H2.85713C2.54153 12.4286 2.28569 12.1727 2.28569 11.8571C2.28569 11.5415 2.54153 11.2857 2.85713 11.2857H9.71427C10.0299 11.2857 10.2857 11.5415 10.2857 11.8571C10.2857 12.1727 10.0299 12.4286 9.71427 12.4286ZM9.71427 10.1429H2.85713C2.54153 10.1429 2.28569 9.88702 2.28569 9.57142C2.28569 9.25582 2.54153 8.99998 2.85713 8.99998H9.71427C10.0299 8.99998 10.2857 9.25582 10.2857 9.57142C10.2857 9.88702 10.0299 10.1429 9.71427 10.1429ZM9.71427 7.85714H2.85713C2.54153 7.85714 2.28569 7.6013 2.28569 7.2857C2.28569 6.9701 2.54153 6.71426 2.85713 6.71426H9.71427C10.0299 6.71426 10.2857 6.9701 10.2857 7.2857C10.2857 7.6013 10.0299 7.85714 9.71427 7.85714ZM14.8571 14.7143C14.8571 15.3454 14.3454 15.8571 13.7143 15.8571C13.0831 15.8571 12.5714 15.3454 12.5714 14.7143V6.14285H14.8572V14.7143H14.8571Z"
            fill="#1DCC69"
        />
    </svg>
    <svg
        *ngIf="type === 'verification'"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.6667 2.99984H9.88C9.6 2.2265 8.86667 1.6665 8 1.6665C7.13333 1.6665 6.4 2.2265 6.12 2.99984H3.33333C2.6 2.99984 2 3.59984 2 4.33317V13.6665C2 14.3998 2.6 14.9998 3.33333 14.9998H12.6667C13.4 14.9998 14 14.3998 14 13.6665V4.33317C14 3.59984 13.4 2.99984 12.6667 2.99984ZM8 2.99984C8.36667 2.99984 8.66667 3.29984 8.66667 3.6665C8.66667 4.03317 8.36667 4.33317 8 4.33317C7.63333 4.33317 7.33333 4.03317 7.33333 3.6665C7.33333 3.29984 7.63333 2.99984 8 2.99984ZM8.66667 12.3332H5.33333C4.96667 12.3332 4.66667 12.0332 4.66667 11.6665C4.66667 11.2998 4.96667 10.9998 5.33333 10.9998H8.66667C9.03333 10.9998 9.33333 11.2998 9.33333 11.6665C9.33333 12.0332 9.03333 12.3332 8.66667 12.3332ZM10.6667 9.6665H5.33333C4.96667 9.6665 4.66667 9.3665 4.66667 8.99984C4.66667 8.63317 4.96667 8.33317 5.33333 8.33317H10.6667C11.0333 8.33317 11.3333 8.63317 11.3333 8.99984C11.3333 9.3665 11.0333 9.6665 10.6667 9.6665ZM10.6667 6.99984H5.33333C4.96667 6.99984 4.66667 6.69984 4.66667 6.33317C4.66667 5.9665 4.96667 5.6665 5.33333 5.6665H10.6667C11.0333 5.6665 11.3333 5.9665 11.3333 6.33317C11.3333 6.69984 11.0333 6.99984 10.6667 6.99984Z"
            fill="#FCD66D"
        />
    </svg>
    {{ text }}
</div>
