// environment-banner.component.ts
import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-environment-banner',
    templateUrl: './environment-banner.component.html',
    styleUrls: ['./environment-banner.component.css'],
})
export class EnvironmentBannerComponent {
    bannerText: string;

    ngOnInit(): void {
        this.bannerText = environment.bannerText;
    }
}
