import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersonalInformation } from '../../../../core/models/ClanMyData';
import { UserService } from '../../../../core/services/user.service';
import { VisualisationService } from '../../../../core/services/visualisation.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    userName: string;
    ssn: string;
    isDarkTheme: boolean;
    logoColor = 'white';
    showPortfolio = false;
    showClanLogo = false;

    constructor(
        private userService: UserService,
        private router: Router,
        private visualisationService: VisualisationService
    ) {
        this.showClanLogo = window.location.hostname === /*'localhost'*/ 'employee.pv-clan.ch';
        this.userService.$userData.subscribe((data) => {
            this.userName = HeaderComponent.getUserFullName(data);
            this.ssn = data.ssn13;
        });
        this.visualisationService.$darkTheme.subscribe((data) => (this.isDarkTheme = data));
    }

    private static getUserFullName(userData: PersonalInformation) {
        return `${userData.firstName} ${userData.lastName}`;
    }

    async openHomePage() {
        await this.router.navigate(['/']);
    }

    ngOnInit() {}

    togglePortfolio() {
        this.showPortfolio = !this.showPortfolio;
        this.visualisationService.changePortfolio(this.showPortfolio);
    }
}
