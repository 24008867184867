import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-more-button',
    templateUrl: './more-button.component.html',
    styleUrls: ['./more-button.component.scss'],
})
export class MoreButtonComponent implements OnInit {
    @Input() withArrow: boolean;

    constructor() {}

    ngOnInit(): void {}
}
