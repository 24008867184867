import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CurrencyService {
    private currencyValuesVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    get $currencyValuesVisibility(): Observable<boolean> {
        return this.currencyValuesVisibility.asObservable();
    }

    toggleCurrencyValuesVisibility() {
        this.currencyValuesVisibility.next(!this.currencyValuesVisibility.value);
    }
}
