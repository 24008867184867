import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { v4 as uuidv4 } from 'uuid';

export type CropperDialogData = {
    image: File;
    maintainAspectRatio: boolean;
    aspectRatioX: number;
    aspectRatioY: number;
    resizeToHeight: number;
    resizeToWidth: number;
};

export type CropperDialogResult = {
    blob: Blob;
    imageUrl: string;
};

@Component({
    selector: 'app-image-cropper-dialog',
    templateUrl: './image-cropper-dialog.component.html',
    styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialogComponent implements AfterViewInit {
    @Input() data: CropperDialogData;
    @Output() croppedResult: EventEmitter<File> = new EventEmitter<File>();
    @ViewChild('imageCropperDialog') dialogElement!: ElementRef<HTMLDialogElement>;
    result: CropperDialogResult = undefined;
    isLoading = true;

    constructor() {}

    ngAfterViewInit(): void {
        this.openDialog();
    }

    openDialog() {
        if (this.dialogElement && this.dialogElement.nativeElement) {
            // @ts-ignore
            (this.dialogElement.nativeElement as HTMLDialogElement).showModal();
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        const { blob, objectUrl } = event;
        if (blob && objectUrl) {
            this.result = { blob, imageUrl: objectUrl };
        }
    }

    sendCroppedImage() {
        const fileName = uuidv4() + '.' + this.result.blob.type.split('/')[1];
        const file = this.blobToFile(this.result.blob, fileName);
        this.croppedResult.emit(file);
        // @ts-ignore
        (this.dialogElement.nativeElement as HTMLDialogElement).close();
    }

    blobToFile(blob: Blob, fileName: string): File {
        const file = new File([blob], fileName, { type: blob.type });
        return file;
    }

    cropperReady() {
        this.isLoading = false;
    }
}
