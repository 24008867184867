import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'app-amount-line',
    templateUrl: './amount-line.component.html',
    styleUrls: ['./amount-line.component.scss'],
})
export class AmountLineComponent implements OnInit, OnChanges {
    @Output() setCurrentAmount: EventEmitter<number> = new EventEmitter();
    @Input() minAmount: number;
    @Input() maxAmount: number;
    @Input() currentAmount: number;
    @Input() isDate = false;
    public percentsForCurrent: number;
    public points: Array<any>;
    public bubblePosition: string;
    dateBubble: string;

    constructor() {}

    ngOnInit(): void {}
    ngOnChanges(): void {
        this.percentsForCurrent = ((this.currentAmount - this.minAmount) * 100) / (this.maxAmount - this.minAmount);
        if (this.percentsForCurrent < 0) {
            this.percentsForCurrent = 0;
        }
        if (this.percentsForCurrent > 100) {
            this.percentsForCurrent = 100;
        }
        if (this.isDate) {
            this.dateBubble = moment(new Date(this.currentAmount)).format('D MMM');
        }
        this.bubblePosition =
            'calc(' +
            this.percentsForCurrent +
            '% - ' +
            ((this.isDate ? this.dateBubble : this.currentAmount + '').length + 1) * 5.5 +
            'px - 8px)';
        this.points = [];
        const step = Math.floor((this.maxAmount - this.minAmount) / 6 / 1000) * 1000;
        let current = this.minAmount;
        while (current <= this.maxAmount) {
            if (!this.isDate) {
                this.points.push({
                    count: current,
                    left: ((current - this.minAmount) * 100) / (this.maxAmount - this.minAmount),
                    leftBody:
                        current === this.minAmount
                            ? 0
                            : current === this.maxAmount
                            ? 'calc(100% - ' + 6 * 8 + 'px)'
                            : 'calc(' + (current * 100) / (this.maxAmount - this.minAmount) + '% - ' + 3 * 8 + 'px)',
                });
            } else {
                this.points.push({
                    count: moment(new Date(current)).format('D MMM'),
                    left: ((current - this.minAmount) * 100) / (this.maxAmount - this.minAmount),
                    leftBody:
                        current === this.minAmount
                            ? 0
                            : current === this.maxAmount
                            ? this.isDate
                                ? 'calc(100% - ' + 46 + 'px)'
                                : 'calc(100% - ' + 40 + 'px)'
                            : 'calc(' +
                              ((current - this.minAmount) * 100) / (this.maxAmount - this.minAmount) +
                              '% - ' +
                              3 * 8 +
                              'px)',
                });
            }
            current += step;
        }
    }
    clickLine(event): void {
        const lineLength = document.getElementById('amount-line-object').clientWidth;
        this.setCurrentAmount.emit(Math.round((this.maxAmount - this.minAmount) * (event.offsetX / lineLength)));
    }
}
