import { HttpParams } from '@angular/common/http';

export function setHttpParams(params: HttpParams, data: any, mapping: { [key: string]: string } = {}): HttpParams {
    Object.keys(data).forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
            const paramKey = mapping[key] || key;
            params = params.set(paramKey, data[key]);
        }
    });
    return params;
}
