import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-like-button',
    templateUrl: './like-button.component.html',
    styleUrls: ['./like-button.component.scss'],
})
export class LikeButtonComponent implements OnInit {
    @Output() clickLikeButton: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    likeButtonClick(): void {
        this.clickLikeButton.emit();
    }

    ngOnInit(): void {}
}
