import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AkGuard } from './core/guards/ak.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { CabinetGuard } from './core/guards/cabinet.guard';
import { PensionerGuard } from './core/guards/pensioner-guard';
import { EmployerGuard } from './core/guards/employer.guard';
import { HasCmsGuard } from './core/guards/has-cms.guard';
import { NewsGuard } from './core/guards/news.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
    },
    // home page that should be show in cases where cabinet in company configuration is set to false
    // in other cases there is home page inside cabinet module
    {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [],
    },
    {
        path: 'cabinet',
        loadChildren: () => import('./modules/cabinet/cabinet.module').then((m) => m.CabinetModule),
        canActivate: [AuthGuard, CabinetGuard],
    },
    {
        path: 'ak',
        loadChildren: () => import('./modules/ak/ak.module').then((m) => m.AkModule),
        canActivate: [AuthGuard, AkGuard],
    },
    {
        path: 'my-documents',
        loadChildren: () => import('./modules/my-documents/my-documents.module').then((m) => m.MyDocumentsModule),
    },
    {
        path: 'news',
        loadChildren: () => import('./modules/news/news.module').then((m) => m.NewsModule),
        canActivate: [AuthGuard, NewsGuard],
    },
    {
        path: 'content-management',
        loadChildren: () => import('./modules/content-management/cm.module').then((m) => m.CMModule),
        canActivate: [AuthGuard, EmployerGuard, HasCmsGuard],
    },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
