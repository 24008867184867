import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../../core/services/currency.service';

@Pipe({
    name: 'currencyVisibility',
    pure: false,
})
export class CurrencyVisibilityPipe implements PipeTransform {
    private currencyVisibility = true;
    constructor(private currencyService: CurrencyService) {}
    transform(value: string | number): string | 'hide' {
        const inputValue = typeof value === 'string' ? value : value.toString();
        this.currencyService.$currencyValuesVisibility.subscribe((isCurrencyVisible) => {
            this.currencyVisibility = isCurrencyVisible;
        });
        return this.currencyVisibility ? inputValue : 'hide';
    }
}
