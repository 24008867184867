import { PurchaseData } from './PurchaseData';
import Transaction, { Originator } from './Transaction';
import { TransactionStatus } from './TransactionStatus';
import { TransactionType } from './TransactionType';

export default class PurchaseTransaction implements Transaction {
    _id: string;
    assignee: string;
    originator: Originator;
    status: TransactionStatus;
    timestamp: string;
    transactionId: string;
    transactionType: TransactionType;
    data: PurchaseData;
    tenant: string;
    company: string;
    processIdPvClan: string;

    constructor() {
        this.transactionType = TransactionType.EMPLOYEE_PURCHASE;
    }
}
