import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PaginationSerice {
    private pageToSet = new BehaviorSubject(1);
    currentPage = this.pageToSet.asObservable();

    private pageToSet2 = new BehaviorSubject(1);
    currentPage2 = this.pageToSet.asObservable();
    constructor() {}

    changePage(message: number) {
        this.pageToSet.next(message);
    }
    changePage2(message: number) {
        this.pageToSet2.next(message);
    }
}
