<div class="datepicker" [ngClass]="{ light: (1 | colourTheme) === false }">
    <mat-form-field appearance="fill">
        <mat-label></mat-label>
        <div>
            <div class="input-wrap">
                <input readonly matInput [matDatepicker]="picker" [formControl]="formControl" />
            </div>
            <div class="picker-wrap">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            </div>
        </div>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>
