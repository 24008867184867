import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CustomConfigurationService {
    get apiUrl(): string {
        return environment.clanDataApiUrl;
    }

    private hasCMS: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    private news: ReplaySubject<boolean> = new ReplaySubject<boolean>();

    public isInPilotGroup: ReplaySubject<any> = new ReplaySubject<any>(null);
    public isInPilotGroup$: Observable<any> = this.isInPilotGroup.asObservable();

    hasCMS$ = this.hasCMS.asObservable();
    news$ = this.news.asObservable();

    constructor(public http: HttpClient) {}

    setCMS(value: boolean) {
        this.hasCMS.next(value);
    }

    setNews(value: boolean) {
        this.news.next(value);
    }

    setInPilotGroup(value: any) {
        this.isInPilotGroup.next(value);
    }

    getCustomConfiguration$(name: string): Observable<any> {
        const url = `${this.apiUrl}/custom-configs/getByName/${name}`;
        return this.http.get<any>(url);
    }
}
