<div
    class="feed-card"
    [ngClass]="{
        notification: feedCard.type === 'notification',
        vote: feedCard.type === 'vote',
        verification: feedCard.type === 'verification',
        light: (1 | colourTheme) === false
    }"
>
    <div class="feed-card__indicator"></div>

    <div class="feed-card__title feed-title">
        <div class="feed-title__wrapper" [ngClass]="{ light: (1 | colourTheme) === false }">
            <div class="feed-title__top title-top">
                <div class="title-top__chip">{{ feedCard.typeValue }}</div>
                <div
                    class="title-top__like-button"
                    *ngIf="feedCard.type === 'vote'"
                    [ngClass]="{
                        'title-top__like-button--active': feedCard.favorite,
                        light: (1 | colourTheme) === false
                    }"
                    (click)="addCardToFavorite(feedCard.title)"
                >
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 14.68L6.84 13.624C2.72 9.888 0 7.424 0 4.4C0 1.936 1.936 0 4.4 0C5.792 0 7.128 0.648 8 1.672C8.872 0.648 10.208 0 11.6 0C14.064 0 16 1.936 16 4.4C16 7.424 13.28 9.888 9.16 13.632L8 14.68Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
                <div *ngIf="feedCard.type === 'notification'" class="title-top__close-button">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.24">
                            <path
                                d="M18 7.20857L16.7914 6L12 10.7914L7.20857 6L6 7.20857L10.7914 12L6 16.7914L7.20857 18L12 13.2086L16.7914 18L18 16.7914L13.2086 12L18 7.20857Z"
                                [attr.fill]="(1 | colourTheme) === false ? 'white' : 'black'"
                            />
                        </g>
                    </svg>
                </div>
            </div>
            <div *ngIf="feedCard.type === 'vote' && feedCard.date" class="feed-title__date title-date">
                <div class="title-date__icon" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            opacity="0.6"
                            d="M10.5799 10.1667L8.33326 8.83334V5.81334C8.33326 5.54667 8.11992 5.33334 7.85326 5.33334H7.81326C7.54659 5.33334 7.33326 5.54667 7.33326 5.81334V8.96001C7.33326 9.19334 7.45326 9.41334 7.65992 9.53334L10.0933 10.9933C10.3199 11.1267 10.6133 11.06 10.7466 10.8333C10.8866 10.6 10.8133 10.3 10.5799 10.1667ZM14.1199 3.34001L12.0666 1.63334C11.7866 1.40001 11.3666 1.43334 11.1266 1.72001C10.8933 2.00001 10.9333 2.42001 11.2133 2.66001L13.2599 4.36667C13.5399 4.60001 13.9599 4.56667 14.1999 4.28001C14.4399 4.00001 14.3999 3.58001 14.1199 3.34001ZM2.73326 4.36667L4.77992 2.66001C5.06659 2.42001 5.10659 2.00001 4.86659 1.72001C4.63326 1.43334 4.21326 1.40001 3.93326 1.63334L1.87992 3.34001C1.59992 3.58001 1.55992 4.00001 1.79992 4.28001C2.03326 4.56667 2.45326 4.60001 2.73326 4.36667ZM7.99992 2.66667C4.68659 2.66667 1.99992 5.35334 1.99992 8.66667C1.99992 11.98 4.68659 14.6667 7.99992 14.6667C11.3133 14.6667 13.9999 11.98 13.9999 8.66667C13.9999 5.35334 11.3133 2.66667 7.99992 2.66667ZM7.99992 13.3333C5.42659 13.3333 3.33326 11.24 3.33326 8.66667C3.33326 6.09334 5.42659 4.00001 7.99992 4.00001C10.5733 4.00001 12.6666 6.09334 12.6666 8.66667C12.6666 11.24 10.5733 13.3333 7.99992 13.3333Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
                <span class="title-date__text" [ngClass]="{ light: (1 | colourTheme) === false }">{{
                    feedCard.date
                }}</span>
            </div>
        </div>
    </div>

    <div class="feed-card__content feed-content">
        <div class="feed-content__wrapper">
            <div class="feed-content__image content-image" *ngIf="feedCard.type !== 'verification'">
                <img *ngIf="feedCard.imageLink" [src]="feedCard.imageLink" width="64" alt="" />
                <svg
                    *ngIf="feedCard.type === 'notification' && !feedCard.imageLink"
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        opacity="0.1"
                        width="64"
                        height="64"
                        rx="32"
                        [attr.fill]="(1 | colourTheme) === false ? 'black' : 'white'"
                    />
                    <path
                        d="M32 42C33.1 42 34 41.1 34 40H30C30 41.1 30.89 42 32 42ZM38 36V31C38 27.93 36.36 25.36 33.5 24.68V24C33.5 23.17 32.83 22.5 32 22.5C31.17 22.5 30.5 23.17 30.5 24V24.68C27.63 25.36 26 27.92 26 31V36L24 38V39H40V38L38 36Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div class="feed-content__text content-text">
                <div
                    class="content-text__title"
                    [ngStyle]="{
                        'margin-bottom': feedCard.text ? '0.3125rem' : null
                    }"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                >
                    {{ feedCard.title }}
                </div>
                <div class="content-text__text" [ngClass]="{ light: (1 | colourTheme) === false }">
                    {{ feedCard.text }}
                </div>
            </div>
        </div>
    </div>

    <div class="feed-card__actions feed-actions" *ngIf="feedCard.type !== 'notification'">
        <button
            (click)="voteButtonClick(feedCard)"
            *ngIf="feedCard.type === 'vote'"
            type="button"
            class="feed-actions__button action-button"
            [ngClass]="{ light: (1 | colourTheme) === false }"
        >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.99992 1.33334C4.31992 1.33334 1.33325 4.32 1.33325 8C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8C14.6666 4.32 11.6799 1.33334 7.99992 1.33334ZM6.66659 11.3333L3.33325 8L4.27325 7.06L6.66659 9.44667L11.7266 4.38667L12.6666 5.33334L6.66659 11.3333Z"
                    fill="currentColor"
                />
            </svg>
            {{ 'cabinet.home.sidebar.voteCard.voteNow' | translate }}
        </button>
        <button *ngIf="feedCard.type === 'verification'" type="button" class="feed-actions__button action-button">
            {{ 'cabinet.home.sidebar.verificationCard.approve' | translate }}
        </button>
        <button
            *ngIf="feedCard.type === 'verification'"
            type="button"
            class="feed-actions__button action-button action-button--transparent"
        >
            {{ 'cabinet.home.sidebar.verificationCard.decline' | translate }}
        </button>
    </div>

    <div class="feed-card__vote-list vote-list" *ngIf="voteListVisibility">
        <div class="vote-list__dialog" [ngClass]="{ light: (1 | colourTheme) === false }">
            <div class="vote-list__top top">
                <div
                    class="top__arrow"
                    [ngClass]="{ light: (1 | colourTheme) === false }"
                    (click)="toggleVoteListVisibility(false)"
                >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20.7961 11.2398V13.2398H8.79605L14.2961 18.7398L12.8761 20.1598L4.95605 12.2398L12.8761 4.31982L14.2961 5.73982L8.79605 11.2398H20.7961Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
                <div class="top__title" [ngClass]="{ light: (1 | colourTheme) === false }">
                    {{ 'cabinet.home.sidebar.voteList.title' | translate }}:
                    <span class="name">{{ feedCard.title }}</span>
                </div>
            </div>

            <div class="vote-list__content content" *ngIf="feedCard.voteList">
                <div class="content-wrapper" [ngClass]="{ light: (1 | colourTheme) === false }">
                    <div class="content-list">
                        <div class="content-list__item" *ngFor="let voteCard of feedCard.voteList; let i = index">
                            <app-vote-card [voteCard]="voteCard" [index]="i + 1"></app-vote-card>
                        </div>
                    </div>
                    <div class="content-actions">
                        <div class="content-actions__checkbox action-checkbox">
                            <app-checkbox
                                [text]="'cabinet.home.sidebar.voteList.checkboxText' | translate"
                            ></app-checkbox>
                            <!--              <span>I want to be notified about the final result</span>-->
                        </div>
                        <div class="content-actions__buttons action-buttons">
                            <div class="back-button action-button">
                                <app-common-button
                                    [height]="35"
                                    [disabled]="false"
                                    [transparent]="true"
                                    [text]="'cabinet.home.sidebar.voteList.backToVote' | translate"
                                ></app-common-button>
                            </div>
                            <div class="submit-button action-button">
                                <app-common-button
                                    [height]="35"
                                    [disabled]="false"
                                    [text]="'cabinet.home.sidebar.voteList.submit' | translate"
                                ></app-common-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
