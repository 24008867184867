import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pension-calc-graph',
    templateUrl: './pension-calc-graph.component.html',
    styleUrls: ['./pension-calc-graph.component.scss'],
})
export class PensionCalcGraphComponent implements OnInit {
    @Input() percentValue = 0;
    @Input() projectedValue = 0;
    @Input() width = 390;
    @Input() height = 339;

    private readonly minGraphValue = -200;
    private readonly maxGraphValue = 25;

    constructor() {}

    ngOnInit(): void {}

    calculateDeg(percentValue: number): number {
        return (
            ((Math.abs(this.minGraphValue) + this.maxGraphValue) * percentValue) / 100 - Math.abs(this.minGraphValue)
        );
    }
}
