import { catchError, map, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root',
})
export class EmployerGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.userService.isEmployer$.pipe(
            map((isEmployer) => {
                if (isEmployer) return true;

                this.router.navigate(['/home']);
                return false;
            }),
            catchError((error) => {
                this.router.navigate(['/home']);
                return of(false);
            })
        );
    }
}
