<div class="preloader" [ngClass]="{ light: (1 | colourTheme) === false }">
    <div class="preloader-loading" [ngClass]="{ light: (1 | colourTheme) === false }">
        <!-- Spinner -->
        <div class="spinnerCenterAlignContainer">
            <div class="spinnerContainer">
                <div class="dotContainer"><div class="dot-left"></div></div>
                <div class="dotContainer"><div class="dot-middle"></div></div>
                <div class="dotContainer"><div class="dot-right"></div></div>
            </div>
        </div>
    </div>
</div>
